import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useSelectedConsumer } from '../App';
import { Select, MenuItem, InputLabel, FormControl, Button } from '@mui/material';

function ConsumerForm({ onConsumerChange }) {
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [consumers, setConsumers] = useState([]);
  const { selectedConsumer } = useSelectedConsumer();
  //const [selectedConsumer, setSelectedConsumer] = useState('');
  const [consumerProfile, setConsumerProfile] = useState('');
  const [billingData, setBillingData] = useState('');
  const [invoiceData, setInvoiceData] = useState('');
  const [usageData, setUsageData] = useState('');
  const [servicePointData, setServicePointData] = useState('');
  const [personalInfo, setPersonalInfo] = useState('');
  const [accountInfo, setAccountInfo] = useState('');
  const [bizaCdrAgreementId, setBizaCdrAgreementId] = useState(undefined);
  const [refreshRequestedToggle, setRefreshRequestedToggle] = useState(false);
  const [activeAgreements, setActiveAgreements] = useState([]);

  /*
    useEffect(() => {
      setIsLoading(true);
      axios.get(process.env.REACT_APP_API_URL + 'consumerIds')
        .then(response => {
          setConsumers(response.data);
        })
        .catch(error => {
          console.error('Error fetching consumer IDs:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, []);*/

  useEffect(() => {
    if (selectedConsumer) {
      setIsLoading(true);
      console.debug(`Getting data for ${selectedConsumer}`)
      axios.get(`${process.env.REACT_APP_API_URL}consumers/${selectedConsumer}?ignoreOrigin=true`)
        .then(response => {
          const consumer = response.data;
          setConsumerProfile(JSON.stringify(consumer.profile, null, 2));
          setBillingData(JSON.stringify(consumer.billing, null, 2));
          setInvoiceData(JSON.stringify(consumer.invoices, null, 2));
          setServicePointData(JSON.stringify(consumer.servicePoints, null, 2));
          setUsageData(JSON.stringify(consumer.usage, null, 2));
          setAccountInfo(JSON.stringify(consumer.accounts, null, 2));
          determineActiveAgreements(consumer.profile);
          console.debug(`Got data for ${selectedConsumer}`);
        })
        .catch(error => {
          console.error(`Error fetching consumer details for ${selectedConsumer}:`, error);
          showToast('fail', `Error fetching consumer data! ${JSON.stringify(error)}`);
        })
        .finally(() => {
          setBizaCdrAgreementId(undefined);
          setIsLoading(false);
        });
    } else {
      console.debug(`No selected consumer, so will blank out the text boxes`);
      setConsumerProfile('');
      setBillingData('');
      setUsageData('');
      setAccountInfo('');
      setInvoiceData('');
      setServicePointData('');
    }
  }, [selectedConsumer, refreshRequestedToggle]);

  // Adding and removing the keypress event listener
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    // Cleanup function
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // Empty dependency array ensures this only runs once

  const determineActiveAgreements = (profile) => {
    const agreements = Object.keys(profile.cdrAgreements)
    const activeAgreements = agreements.filter((agreement) => profile.cdrAgreements[agreement].status === "ACTIVE");
    const activeAgreementList = activeAgreements.map((agreement) => {
      return { agreementId: agreement, dhName: profile.cdrAgreements[agreement].details.activated?.participant.participantName };
    });

    setActiveAgreements(activeAgreementList);
  }

  const handleSave = () => {
    setIsLoading(true);

    let data;

    try {
      data = {
        consumerProfile: JSON.parse(consumerProfile),
        billingData: JSON.parse(billingData),
        invoiceData: JSON.parse(invoiceData),
        servicePointData: JSON.parse(servicePointData),
        usageData: JSON.parse(usageData),
        accountInfo: JSON.parse(accountInfo),
      };
    } catch (error) {
      console.error(`Could not parse data`, error);
      setIsLoading(false);
      showToast('fail', `Could not parse data! ${error}`);
      return;
    }

    if (selectedConsumer) {
      // If a consumer is selected, update the existing consumer
      axios.put(`${process.env.REACT_APP_API_URL}admin/consumers/${selectedConsumer}?ignoreOrigin=true`, data)
        .then(response => {
          console.log('Consumer updated:', response.data);
          showToast('success', 'Consumer updated OK ✅');
        })
        .catch(error => {
          showToast('fail', `Error updating consumer! ${JSON.stringify(error)}`);
          console.error('Error updating consumer:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      // If no consumer is selected, create a new consumer
      axios.post(process.env.REACT_APP_API_URL + 'admin/consumers?ignoreOrigin=true', data)
        .then(response => {
          console.log('New consumer created:', response.data);
          showToast('success', 'New consumer created ✅');
        })
        .catch(error => {
          console.error('Error creating consumer:', error);
          showToast('fail', `Error creating new consumer! ${JSON.stringify(error)}`);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // Ref to hold the latest version of handleSave
  const handleSaveRef = useRef(handleSave);

  // Update the ref each time handleSave changes
  useEffect(() => {
    handleSaveRef.current = handleSave;
  }, [handleSave]);

  const deleteConsumer = () => {
    const isSure = window.confirm(`Are you sure you want to delete this consumer?`);
    if (isSure) {
      axios.delete(`${process.env.REACT_APP_API_URL}admin/consumers/${selectedConsumer}`)
        .then(response => {
          console.debug(`Deleted consumer ${selectedConsumer}`);
        })
        .catch(error => {
          console.error(`Error deleting consumer '${selectedConsumer}':`, error);
        }).finally(() => {
          //stopLoadingSpinner('comparisonChartBox');
        });

    }
  }


  const loadDataFromBiza = () => {
    setIsLoading(true);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}admin/cdr/collect`,
      data: {
        consumerId: selectedConsumer,
        agreementId: bizaCdrAgreementId
      }
    };

    console.debug(`Requesting fresh collection of CDR data for ${selectedConsumer} with agreement ${bizaCdrAgreementId}`);
    axios.request(config)
      .then((response) => {
        console.debug(`Collected fresh CDR data OK`)
        setIsLoading(false);
        showToast('success', `Succesfully loaded the consumer data from Biza`)
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        showToast('fail', `Error getting data from Biza: ${JSON.stringify(error.response?.data || error, null, 2)}`);
      })
      .finally(() => {
        console.debug(`Requesting refresh of data on page now that CDR data has been freshly collected`)
        setIsLoading(true);
        setRefreshRequestedToggle(!refreshRequestedToggle);
      });
  }

  const showToast = (type, message) => {
    setMessage((new Date()).toISOString() + ': ' + message);
    setShowMessage(true);
    setMessageType(type);
    // Hide the message after n seconds
    /*
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
    */

  }

  const closeToast = () => {
    setShowMessage(false);
  };

  // Key down handler
  const handleKeyDown = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 's') {
      event.preventDefault(); // Prevent the browser's save dialog from opening
      handleSaveRef.current(); // Use the current version of handleSave
    }
  };


  return (
    <div className="contentBody">
      {showMessage && <div className={`flash-banner ${messageType}`}>
        <pre>{message}</pre>
        <button onClick={closeToast} className="close-toast">
          &times; {/* This is a simple cross character, you can use an icon here */}
        </button>
      </div>}
      <h2>Consumer Admin - {selectedConsumer}</h2>
      <FormControl sx={{ m: 1, minWidth: 500 }} size='small'>
        <InputLabel id="active-agreements-select">Biza OG Aagreement ID</InputLabel>
        <Select labelId="active-agreements-select" autowidth onChange={(e) => setBizaCdrAgreementId(e.target.value)} value={bizaCdrAgreementId ?? ''}>
          {activeAgreements.map((agreement) => {
            return <MenuItem key={agreement.agreementId} value={agreement.agreementId}>{agreement.dhName}: {agreement.agreementId}</MenuItem>
          })
          }
        </Select>
      </FormControl>
      <br />
      <Button variant="outlined" onClick={loadDataFromBiza}>Collect all data from DH via Biza OG</Button>
      <br /><br />
      <Button variant="outlined" o onClick={handleSave}>Save current data for consumer</Button>
      <br /><br />
      <Button variant="outlined" o onClick={() => setRefreshRequestedToggle(!refreshRequestedToggle)}>Refresh data from DB</Button>


      {isLoading && (
        <div style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          background: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999,
        }}>
          {/* Replace "Loading..." with your actual loading spinner */}
          <div>Loading...</div>
        </div>
      )}

      <label id="consumerProfile" className="inputBlock">Consumer profile:
        <textarea
          value={consumerProfile}
          onChange={(e) => setConsumerProfile(e.target.value)}
          placeholder="Enter consumer profile here"
        />

      </label>

      <label id="consumerAccounts" className="inputBlock">Consumer accounts:
        <textarea
          value={accountInfo}
          onChange={(e) => setAccountInfo(e.target.value)}
          placeholder="Enter account information here"
        />
      </label>

      <label id="consumerInvoices" className="inputBlock">Consumer invoices:
        <textarea
          value={invoiceData}
          onChange={(e) => setInvoiceData(e.target.value)}
          placeholder="Enter invoice data here"
        />

      </label>

      <label id="consumerBills" className="inputBlock">Consumer bills:
        <textarea
          value={billingData}
          onChange={(e) => setBillingData(e.target.value)}
          placeholder="Enter billing data here"
        />

      </label>

      <label id="consumerServicePoints" className="inputBlock">Consumer service points:
        <textarea
          value={servicePointData}
          onChange={(e) => setServicePointData(e.target.value)}
          placeholder="Enter service point data here"
        />

      </label>

      <label id="consumerUsage" className="inputBlock">Consumer usage:
        <textarea
          value={usageData}
          onChange={(e) => setUsageData(e.target.value)}
          placeholder="Enter usage data here"
        />

      </label>

      <button onClick={deleteConsumer}>Delete Consumer</button>
      <br /><br />

    </div>
  );
}





export default ConsumerForm;
